<template>
    <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210902154400546.png"/>
</template>

<script>
  export default {
    name: "Release",
    created() {
    },
    methods: {
    }
  }
</script>

<style scoped>
    img {
        width: 100%;
    }
</style>
